import './style.scss';
import { Fab } from '@mui/material';
import Constants from '../../data/Constants';

const FloatingActionButton = () => {
    return (
        <div className='floatingactionbutton-container'>
            {
                Constants.socialLinks.filter(link => link.floatingActionButtonActived).map((link, index) => {
                    return (
                        <Fab className={`floatingactionbutton-sociallink ${link.className}`} key={index} onClick={_ => window.open(link.url)}>
                            {link.icon}
                        </Fab>
                    );
                })
            }
        </div>
    );
}

export default FloatingActionButton;