
import Amil from '../assets/images/agreements/amil.png';
import Bradesco from '../assets/images/agreements/bradesco.png';
import Cassi from '../assets/images/agreements/cassi.png';
// import CruzAzul from '../assets/images/agreements/cruz_azul.png';
import Economus from '../assets/images/agreements/economus.png';
import GamaSaude from '../assets/images/agreements/gama_saude.png';
import Geap from '../assets/images/agreements/geap.png';
import Mapfre from '../assets/images/agreements/mapfre.png';
import Mediservice from '../assets/images/agreements/mediservice.png';
import Next from '../assets/images/agreements/next.png';
import Notredame from '../assets/images/agreements/notredame.png';
import Omint from '../assets/images/agreements/omint.png';
// import PortoSeguro from '../assets/images/agreements/porto_seguro.png';
import SantaHelena from '../assets/images/agreements/santa_helena.png';
import SulAmerica from '../assets/images/agreements/sul_america.png';
import Unihosp from '../assets/images/agreements/unihosp.png';
import Vivest from '../assets/images/agreements/vivest.png';
import FundacaoSaudeItau from '../assets/images/agreements/fundacao_saude_itau.png';


const AgreementsData = [
    {
        name: "Amil",
        image: Amil
    }, {
        name: "Bradesco Seguro Saúde",
        image: Bradesco
    }, {
        name: "Cassi",
        image: Cassi
    }, {
        name: "Economus",
        image: Economus
    }, {
        name: "Gama Saúde",
        image: GamaSaude
    }, {
        name: "GEAP",
        image: Geap
    }, {
        name: "MAPFRE",
        image: Mapfre
    }, {
        name: "Mediservice",
        image: Mediservice
    }, {
        name: "Next",
        image: Next
    }, {
        name: "NotreDame Intermédica",
        image: Notredame
    }, {
        name: "Omint",
        image: Omint
    }, {
        name: "Santa Helena",
        image: SantaHelena
    }, {
        name: "Sul América",
        image: SulAmerica
    }, {
        name: "Unihosp",
        image: Unihosp
    }, {
        name: "Vivest",
        image: Vivest
    }, {
        name: "Fundação Saúde Itaú",
        image: FundacaoSaudeItau
    }
];

export default AgreementsData;
