import "./style.scss";
import { Grid, Typography } from "@mui/material";
import ServicesData from "../../data/Services";

const Services = () => {
    return (
        <Grid item xs={12} className='grid-section service-section' id='services'>
            <Typography className='subtitle'>Serviços</Typography>
            <Typography className='title'>Conheça um pouco sobre nossas especialidades!</Typography>

            <Grid container className='service-list'>
                {
                    ServicesData.map((service, index) => {
                        return (
                            <Grid item xs={12} className='service-card' key={index}>
                                <img src={service.image} alt={service.name} />
                                
                                <Grid item className='service-text'>
                                    <Typography component='span' className='name'>{service.name}</Typography>
                                    <Typography component='span' className='description'>{service.description}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
}

export default Services;