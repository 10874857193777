import "./style.scss";
import { Grid, Typography } from "@mui/material";
import Wallpaper from '../../assets/images/start/start_1.jpeg';

const Start = () => {
    return (
        <Grid
            item xs={12}
            className='start-section'
            id='start'
            sx={{
                backgroundImage: `url('${Wallpaper}')`
            }}
        >
            <Grid item className='start-section-text'>
                <Typography className='title'>
                    Bem-vindo(a) ao IDR Instituto de Doenças Renais LTDA.
                </Typography>

                <Typography className='subtitle'>
                    Oferecemos tratamento especializado com atenção voltada a doenças renais. 
                    Realizamos consulta ambulatorial com médico nefrologista e serviço de diálise com equipamentos modernos e equipe multiprofissional qualificada e especializada.
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Start;