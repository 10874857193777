import './style.scss';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import Constants from '../../data/Constants';

const Footer = () => {
    return (
        <Grid container className='footer-main'>
            <Grid item md={6} xs={12}>
                <Typography variant='h5'> {Constants.enterpriseName} </Typography>

                <Typography variant='body2' marginTop={2}> {Constants.address.street} </Typography>
                <Typography variant='body2'> {Constants.address.city} </Typography>
                <Typography variant='body2'> {Constants.address.zipCode} </Typography>

                <Typography
                    variant='body2'
                    className='cursor-pointer'
                    marginTop={2}
                    onClick={_ => window.open(`mailto:${Constants.contact.email}`)}
                >
                    {Constants.contact.email}
                </Typography>
            </Grid>

            <Grid item md={6} xs={12} className='grid-sociallinks'>
                <Typography variant='h6'> Nossas mídias sociais </Typography>
                <Typography variant='body2'> Entre em contato conosco através de uma de nossas mídias sociais </Typography>

                <Grid item marginY={2} />
                {
                    Constants.socialLinks.filter(link => link.actived).map((link, index) => {
                        return (
                            <IconButton
                                className={`iconbutton-sociallink ${link.className}`}
                                onClick={_ => window.open(link.url)}
                                key={index}
                            >
                                {link.icon}
                            </IconButton>
                        );
                    })
                }
            </Grid>

            <Grid item xs={12} paddingY={4}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='caption' className='fontweight-600'> {Constants.enterpriseName} </Typography>
                <Typography variant='caption' className='text-copyright'> © Copyright 2022. Todos os direitos reservados </Typography>
            </Grid>

            <Grid item xs={12} paddingTop={1}>
                <Typography variant='caption'> Desenvolvido por </Typography>
                <a href={Constants.developer.url} target='_blank' rel="noreferrer">
                    <Typography variant='caption' >
                        {Constants.developer.name}
                    </Typography>
                </a>
            </Grid>
        </Grid>
    );
}

export default Footer;