import { WhatsApp, Facebook, LinkedIn, YouTube, Instagram, Telegram, Twitter } from '@mui/icons-material';

const Constants = {
    enterpriseName: "IDR | Instituto de Doenças Renais",
    baseUrl: process.env.REACT_APP_BASE_URL || '',
    address: {
        street: "Av. Dom Pedro II, 666",
        city: "Santo André - SP",
        country: "Brasil",
        zipCode: '09080-000',
        googleMapsUrl: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.734292369099!2d-46.53728768502034!3d-23.649684884639083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4293669a88ed%3A0x2a72fdf2c6bb6920!2sIDR%20-%20Instituto%20de%20Doen%C3%A7as%20Renais!5e0!3m2!1spt-BR!2sbr!4v1663866540408!5m2!1spt-BR!2sbr`
    },
    operationTime: [
        'Segunda à quinta: 8h às 17h50min',
        'Sexta: 8h às 16h30min'
    ],
    contact: {
        email: "contato@idrclinica.com.br",
        phone: "(11) 4994-2255",
    },
    socialLinks: [
        {
            name: "WhatsApp",
            icon: <WhatsApp fontSize='medium' />,
            className: 'icon-whatsapp',
            url: "https://api.whatsapp.com/send?phone=5511941387771",
            actived: true,
            floatingActionButtonActived: true
        }, {
            name: "Telegram",
            icon: <Telegram fontSize='medium' />,
            className: 'icon-telegram',
            url: "#",
            actived: false,
            floatingActionButtonActived: false
        }, {
            name: "Instagram",
            icon: <Instagram fontSize='medium' />,
            className: 'icon-instagram',
            url: "#",
            actived: false,
            floatingActionButtonActived: false
        }, {
            name: "Facebook",
            icon: <Facebook fontSize='medium' />,
            className: 'icon-facebook',
            url: "#",
            actived: false,
            floatingActionButtonActived: false
        }, {
            name: "Twitter",
            icon: <Twitter fontSize='medium' />,
            className: 'icon-twitter',
            url: "#",
            actived: false,
            floatingActionButtonActived: false
        }, {
            name: "LinkedIn",
            icon: <LinkedIn fontSize='medium' />,
            className: 'icon-linkedin',
            url: "#",
            actived: false,
            floatingActionButtonActived: false
        }, {
            name: "YouTube",
            icon: <YouTube fontSize='medium' />,
            className: 'icon-youtube',
            url: "#",
            actived: false,
            floatingActionButtonActived: false
        }
    ],
    developer: {
        url: "https://vinibarr.com.br/",
        name: "Vinibarr Sistemas Online"
    }
}

export default Constants;