import './style.scss';
import { Backdrop, Grid, IconButton, Typography } from "@mui/material";
import { Close } from '@mui/icons-material';
import { useState, useEffect } from 'react';

const navbarItemArray = [
    {
        id: 'start',
        name: 'Início'
    }, {
        id: 'services',
        name: 'Serviços'
    }, {
        id: 'agreements',
        name: 'Convênios'
    }, {
        id: 'contact',
        name: 'Fale conosco'
    }, {
        id: 'about',
        name: 'Sobre nós'
    }, 
];

const Navbar = ({
    isOpen,
    handleClose
}) => {
    const [scrollingUp, setScrollingUp] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeNavbarItem, setActiveNavbarItem] = useState(navbarItemArray[0]);

    useEffect(() => {
        setScrollPosition(window.scrollY);
    }, []);


    window.onscroll = () => {
        const newScrollPosition = window.scrollY;

        setActiveNavbarItem(
            navbarItemArray.find(item => {
                const element = document.getElementById(item.id);
                return element && newScrollPosition <= (element.offsetTop + (element.offsetHeight / 2));
            }) || navbarItemArray.at(-1)
        );

        setScrollingUp(newScrollPosition < 100 || newScrollPosition < scrollPosition);
        setScrollPosition(newScrollPosition);
    }


    const handleNavbarItemClick = (id) => {
        const header = document.getElementsByClassName('header-main')[0];
        const section = document.getElementById(id);

        let top = (section.offsetTop - 40) - header.offsetHeight;

        if (window.innerWidth > 900 && top < scrollPosition) {
            const navbar = document.getElementsByClassName('navbar-main')[0];
            top -= navbar.offsetHeight;
        }
        
        window.scroll({
            top: top,
            behavior: 'smooth'
        });

        handleClose(false);
    }

    return (
        <>
            <Grid container className={`navbar-main ${scrollingUp ? 'scrolling-up' : ''} ${isOpen ? 'opened' : ''}`}>
                <IconButton className='trigger-navbar-close' onClick={_ => handleClose(false)}>
                    <Close fontSize='medium' />
                </IconButton>

                {
                    navbarItemArray.map(item => {
                        return (
                            <Typography
                                key={item.id}
                                variant='subtitle1'
                                className={`navbar-item ${item === activeNavbarItem ? 'actived' : ''}`}
                                onClick={_ => handleNavbarItemClick(item.id)}
                            >
                                {item.name}
                            </Typography>
                        );
                    })
                }
            </Grid>

            <Backdrop
                open={isOpen}
                className={`backdrop-navbar ${isOpen ? 'opened' : ''}`}
            />
        </>
    );
}

export default Navbar;