import { createContext, useCallback, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const AlertMessageContext = createContext(undefined);

const AlertMessageContextProvider = (props) => {

    const showAlertMessage = useCallback((type, message, autoClose = 5000) => {
        return toast[type](message, {
            autoClose
        });
    }, []);

    const updateAlertMessage = useCallback((id, type, message, autoClose = 5000) => {
        toast.update(id, {
            render: message, 
            type, 
            autoClose,
            isLoading: false
        });
    }, []);

    const data = {
        showAlertMessage,
        updateAlertMessage
    }

    return (
        <AlertMessageContext.Provider value={data}>
            {props.children}

            <ToastContainer
                position="bottom-right"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={5}
            />
        </AlertMessageContext.Provider>
    );
}


const useAlertMessageContext = () => {
    const context = useContext(AlertMessageContext);
    
    if (context === undefined) {
        throw new Error('useAlertMessageContext must be used within a AlertMessageContextProvider');
    }
    
    return context;
}

export { 
    useAlertMessageContext
};

export default AlertMessageContextProvider;


