
import AlertMessageContextProvider from './AlertMessageContext';
import LoadingContextProvider from './LoadingContext';


const MainContextProvider = (props) => {
    return (
        <>
            <LoadingContextProvider>
                <AlertMessageContextProvider>
                    {props.children}
                </AlertMessageContextProvider>
            </LoadingContextProvider>
        </>
    );
}


export default MainContextProvider;