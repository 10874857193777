import "./style.scss";
import { Button, Card, CardHeader, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Email, Phone, Send } from "@mui/icons-material";
import FormHelper from "../../helpers/FormHelper";
import Constants from "../../data/Constants";
import ContactService from "../../services/ContactService";
import { useAlertMessageContext } from "../../contexts/AlertMessageContext";

const subjectArray = [
    "Dúvida",
    "Reclamação",
    "Sugestão"
];

const Contact = () => {
    const { showAlertMessage, updateAlertMessage } = useAlertMessageContext();

    const [sendingForm, setSendingForm] = useState(false);
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        subject: subjectArray[0],
        message: ''
    });

    
    const handleChangeForm = (target) => {
        const name = target.name;
        const value = target.value;

        setForm({
            ...form, 
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (sendingForm) {
            return;
        }

        setSendingForm(true);

        const alertMessageId = showAlertMessage('loading', 'Enviando e-mail de contato...');
        const formData = FormHelper.GenerateFormData(e);

        ContactService.SendMail(formData).then(resp => {
            updateAlertMessage(alertMessageId, 'success', 'E-mail de contato enviado com sucesso!');
        }).catch(err => {
            updateAlertMessage(alertMessageId, 'error', 'Ocorreu um problema ao tentar enviar o e-mail de contato...');
        }).finally(() => {
            setSendingForm(false);
        });
    }

    return (
        <Grid item xs={12} className='grid-section contact-section' id='contact'>
            <Typography className='subtitle'>Fale conosco</Typography>
            <Typography className='title'>Divida sua opinião com a gente!</Typography>
            
            <Typography className='text' marginTop={2}>
                Envie-nos um e-mail preenchendo as informações do formulário abaixo ou através de alguma outra forma de contato disponível.
            </Typography>

            <Grid container marginTop={2}>
                <Grid item md={5} xs={12} component='form' onSubmit={handleSubmit}>
                    <TextField 
                        label="Nome" 
                        name='name'
                        variant="filled" 
                        margin="normal"
                        fullWidth
                        required
                        value={form.name}
                        onChange={e => handleChangeForm(e.target)}
                    />

                    <TextField 
                        label="E-mail" 
                        type='email'
                        name='email'
                        variant="filled" 
                        margin="normal"
                        fullWidth
                        required
                        value={form.email}
                        onChange={e => handleChangeForm(e.target)}
                    />

                    <TextField 
                        label="Telefone" 
                        name='phone'
                        variant="filled" 
                        margin="normal"
                        fullWidth
                        required
                        value={form.phone}
                        onChange={e => handleChangeForm(e.target)}
                    />

                    <TextField
                        select
                        label="Assunto"
                        name='subject'
                        variant="filled"
                        margin='normal'
                        fullWidth
                        required
                        value={form.subject}
                        onChange={e => handleChangeForm(e.target)}
                    >
                        {
                            subjectArray.map((subject, index) => {
                                return (
                                    <MenuItem key={index} value={subject}>
                                        {subject}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>

                    <TextField 
                        label="Mensagem" 
                        name='message'
                        variant="filled" 
                        margin="normal"
                        fullWidth
                        required
                        value={form.message}
                        onChange={e => handleChangeForm(e.target)}
                        multiline
                        rows={4}
                    />

                    <Grid item marginTop={3} />

                    <Button
                        type='submit'
                        variant='contained'
                        disableElevation
                        startIcon={
                            <Send fontSize='medium' />
                        }
                        className='button-send-form'
                    >
                        Enviar
                    </Button>
                </Grid>

                <Grid item md={3} xs={12} />

                <Grid item md={4} xs={12}>
                    <Card className='card-contact' onClick={_ => window.open(`tel:${Constants.contact.phone}`)}>
                        <CardHeader
                            avatar={
                                <Phone className='icon-contact' />
                            }
                            title={<Typography variant='subtitle1'> {Constants.contact.phone} </Typography>}
                        />  
                    </Card>

                    <Card className='card-contact' onClick={_ => window.open(`mailto:${Constants.contact.email}`)}>
                        <CardHeader
                            avatar={
                                <Email className='icon-contact' />
                            }
                            title={<Typography variant='subtitle1'> {Constants.contact.email} </Typography>}
                        />   
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Contact;