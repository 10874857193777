import "./style.scss";
import { Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import AgreementsData from "../../data/Agreements";

const Agreements = () => {
    return (
        <Grid item xs={12} className='grid-section agreements-section' id='agreements'>
            <Typography className='subtitle'>Convênios</Typography>
            <Typography className='title'>Conheça os convênios que atendemos</Typography>

            <Grid container className='agreements-list'>
                <ImageList cols={6} rowHeight={160}>
                    {
                        AgreementsData.map((agreement, index) => {
                            return (
                                <ImageListItem key={index} className='agreements-item'>
                                    <img src={`${agreement.image}`} alt={agreement.name} title={agreement.name} loading="lazy" />
                                </ImageListItem>
                            );
                        })
                    }
                </ImageList>
            </Grid>
        </Grid>
    );
}

export default Agreements;