import './style.scss';
import { Grid, IconButton, Typography } from '@mui/material';
import { LocationOn, AccessTime, Menu, SupportAgent } from '@mui/icons-material';
import Constants from '../../data/Constants';
import mainLogo from '../../assets/images/mainLogo.png';
import Navbar from '../Navbar';
import { useState } from 'react';

const Header = () => {
    const [navbarOpened, setNavbarOpened] = useState(false);

    return (
        <>
            <Grid container className='header-main'>
                <Grid item className='grid-mainlogo'>
                    <img src={mainLogo} alt={Constants.enterpriseName} />
                </Grid>

                <Grid item className='grid-informations'>
                    <Grid item className='grid-information-item'>
                        <SupportAgent className='icon' />
                        
                        <Grid item>
                            <Typography variant='caption'> {Constants.contact.phone} </Typography>
                            <Typography variant='caption'> {Constants.contact.email} </Typography>
                        </Grid>
                    </Grid>

                    <Grid item className='grid-information-item'>
                        <LocationOn className='icon' />
                        
                        <Grid item>
                            <Typography variant='caption'>{Constants.address.street}</Typography>
                            <Typography variant='caption'>{Constants.address.city}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item className='grid-information-item'>
                        <AccessTime className='icon' />

                        <Grid item>
                            {
                                Constants.operationTime.map((item, index) => {
                                    return (
                                        <Typography variant='caption' key={index}> {item} </Typography>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className='grid-navbar-trigger'>
                    <IconButton className='iconbutton-navbar-trigger' onClick={_ => setNavbarOpened(true)}>
                        <Menu fontSize='medium' />
                    </IconButton>
                </Grid>
            </Grid>

            <Navbar
                isOpen={navbarOpened}
                handleClose={() => setNavbarOpened(false)}
            />
        </>
    );
}

export default Header;