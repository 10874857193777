import ServiceNefrologista from '../assets/images/services/service_nefrologista.jpeg';
import ServiceHemodialise from '../assets/images/services/service_hemodialise.jpeg';
import ServiceDialisePeritoneal from '../assets/images/services/service_dialiseperitoneal.jpeg';

const ServicesData = [
    {
        name: "Consulta ambulatorial com nefrologista",
        description: "A especialidade de nefrologia é voltada a cuidar de doenças que comprometem os rins. Desse modo, o médico nefrologista atende pacientes com cálculo renal, infecção do trato urinário, doença renal cística, nefrites, insuficiência renal aguda, insuficiência renal crônica e faz prevenção da insuficiência renal em pacientes diabéticos, hipertensos e outras doenças que comprometam a função dos rins.",
        image: ServiceNefrologista
    }, {
        name: "Hemodiálise",
        description: `É um procedimento em que a máquina faz a função dos rins limpando e filtrando o sangue. Assim, o tratamento retira do corpo resíduos que não fazem bem ao organismo e líquido através de um filtro artificial.\nÉ um procedimento realizado em paciente com insuficiência renal aguda ou insuficiência renal crônica indicado pelo médico nefrologista.`,
        image: ServiceHemodialise
    }, {
        name: "Diálise Peritoneal",
        description: `É um tipo de diálise onde o peritônio, membrana que reveste o abdômen, faz a função do filtro natural do organismo. Nesse tratamento, assim como na hemodiálise, há retirada de resíduos indesejáveis pelo corpo e líquido.\nÉ indicado para pacientes com insuficiência renal aguda e insuficiência renal crônica após avaliação do médico nefrologista.`,
        image: ServiceDialisePeritoneal
    }
];

export default ServicesData;