import "./style.scss";
import { Grid, Typography } from "@mui/material";
import Constants from "../../data/Constants";
import ImageFrontal from "../../assets/images/frontal.png";

const About = () => {
    return (
        <Grid item xs={12} className='grid-section about-section' id='about'>
            <Typography className='subtitle'>Sobre nós</Typography>
            <Typography className='title'>Conheça um pouco mais sobre nossa história!</Typography>

            <Typography className='text' marginTop={2}>
                O IDR Instituto de Doenças Renais LTDA foi fundado em 1974 na cidade de Santo André - SP, oferecendo assistência especializada em nefrologia.
                É a clínica pioneira em diálise na grande ABC, tendo vasta experiência no setor e com infraestrutura adequada para garantir a melhor assistência aos nossos pacientes.
            </Typography>

            <Typography className='text' marginTop={2}>
                Possuímos corpo clínico especializado e qualificado para atender as necessidades dos nossos pacientes seja na consulta ambulatorial com médico nefrologista ou no serviço de diálise.
            </Typography>

            <Grid container>
                <Grid item md={6} xs={12}>
                    <img src={ImageFrontal} alt={Constants.enterpriseName} className='image-frontal' />
                </Grid>
                
                <Grid item md={6} xs={12}>
                    {
                        Constants.address.googleMapsUrl && (
                            <iframe src={Constants.address.googleMapsUrl} title='GoogleMaps' />
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default About;