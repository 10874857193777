import { createContext, useCallback, useContext, useState } from "react";
import { Backdrop, CircularProgress } from '@mui/material';

const LoadingContext = createContext(undefined);

const LoadingContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const hideLoading = useCallback(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    const showLoading = useCallback(() => {
        setIsLoading(true);
    }, [setIsLoading]);


    const data = {
        hideLoading,
        showLoading
    }

    return (
        <LoadingContext.Provider value={data}>
            {props.children}

            <Backdrop
                open={isLoading}
                sx={{
                    color: '#fff',
                    zIndex: 9999
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </LoadingContext.Provider>
    );
}


const useLoadingContext = () => {
    const context = useContext(LoadingContext);
    
    if (context === undefined) {
        throw new Error('useLoadingContext must be used within a LoadingContextProvider');
    }
    
    return context;
}

export { 
    useLoadingContext
};

export default LoadingContextProvider;


