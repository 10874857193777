import './style.scss';
import { Grid } from '@mui/material';
import About from '../../pages/About';
import Contact from '../../pages/Contact';
import Start from '../../pages/Start';
import Services from '../../pages/Services';
import FloatingActionButton from '../FloatingActionButton';
import Agreements from '../../pages/Agreements';

const Content = () => {
    return (
        <Grid container className='content-main'>
            <Start />
            <Services />
            <Agreements />
            <Contact />
            <About />

            <FloatingActionButton />
        </Grid>
    );
}

export default Content;